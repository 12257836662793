
$(document).ready(function() {
  var targetWaitCount = 0;
    var checkIfTargetLoaded = setInterval(function() {
      targetWaitCount++;

      if(window.digitalData){
        if(window.digitalData.activity.ready === false || targetWaitCount > 60) {
          // manual call when target loading is set to false
          clearInterval(checkIfTargetLoaded);
          callPCP13N();
        } else if (window.digitalData.activity.ready) {
          // stop the checking when we know target is on the page
          clearInterval(checkIfTargetLoaded);
        }

      } else {
        if(typeof(window.targetExperiences) === 'undefined' || window.targetExperiences[0] === false || targetWaitCount > 60){
          // manual call when target loading is set to false
          clearInterval(checkIfTargetLoaded);
          callPCP13N();
        } else if (window.targetExperiences[0]) {
          // stop the checking when we know DTM is on the page
          clearInterval(checkIfTargetLoaded);
        }

      } 
    },50);
});
/**
 * callPCP13N - fallback function when Target does not load.
 * @type {object}
 */
function callPCP13N() {
  var segmentArray=[], countryCode;

  guestLocale.isReady(function() {
    countryCode = localeData.isIntl === false ? localeData.country : "IN";
    segmentArray = [countryCode, localeData.aircity, getCustomerStatus()];
    pcP13n.init(segmentArray);    
  });
};

function getCustomerStatus() {
  var guestInfoObj = guestProfile.getInfo();
  return guestInfoObj && guestInfoObj.futureBooked === "Y" ? "Future+Booking" : "";
  
};
/**
 * pcP13n - global variable to be used when initiating Princess P13N solution
 * @type {object}
 */
var pcP13n = {
/**
 * [init] - triggered from Adobe Target, this funtion iterates over the json data in the page and finds the best match and shows personalized results.
 @object segmentArrayFromTarget - list of segments(Personalization/localization options) from target which will govern what content to show.
*/
init: function(segmentArrayFromTarget){
    //iterate through span tags and pick content id
      $("span[class*='p13nListItemId']").each(function () {
        var $this = jQuery(this),
        p13nId = pcP13n.returnp13nId($this);
        pcP13n.personalize(p13nId, segmentArrayFromTarget);
      });

},
/**
 * [personalize] - The actual processing of finding the best match happens in this function. Match content id with json and then match segments, when any segment match give it a weight an keep adding more, the one with the highest weight win.
 replace content of the span with  winning json row.
 @param $slot - single placeholder on the page which will hold personalized result
 @param id - content id(coming from rhythmyx) associated with it.
 @object segmentArrayFromTarget - list of segments(Personalization/localization options) from target which will govern what content to show. 
 */

personalize: function (id, segmentArrayFromTarget) {

  pcSegmentData.forEach(function(contentItem) {
    if (contentItem.content_id === id){
    var segmentWeightSplit, segments = contentItem.segments;//This contains segment and weight associated with it. Format is <segment>:<weight>

    var weightedSegments = segments.map(function(segment) {
      var totalWeight = segment.segment_ids.reduce(function(weight, segment){
        segmentWeightSplit = segment.split(":");//Spliting the segment and weight here.
        if(segmentArrayFromTarget.indexOf(segmentWeightSplit[0]) > -1){
          weight = weight+parseInt(segmentWeightSplit[1]);
        }
        return weight;
      },0);
      segment.weight = totalWeight;
      return segment;
  });
  var bestSegmentMatch = weightedSegments.sort(function(a, b) {
    return b.weight - a.weight;
  });
  var totalResultsToShow = pcP13n.getTotalCount(contentItem, bestSegmentMatch.length);
  var $pP13NItem = $( ".p13nListItemId_"+id );
  var $htmlItem;
  /** If not segment matches, show blank */
  if(bestSegmentMatch.length > 0 ){
    bestSegmentMatch.every(function (bestSegment, index) { 
        if ( bestSegment.weight > 0 && index < totalResultsToShow ){
          $pP13NItem.after(bestSegment.data);
          $htmlItem = $pP13NItem.next();
          return true;
        } else {
          return false;
        }
    });
    $pP13NItem.remove();
  }
  if( $htmlItem ) { 
    $htmlItem.trigger("p13nAfterDelivery",[{"snippetItems":[{"rendering" : $htmlItem}]}]); 
  } else {
    $pP13NItem.remove();
  }
  
  /*Passing array with 'p13nAfterDelivery' trigger event for some functions that are using bestSegmentMatch.data for processing */
}// end of contentItem.content_id===id

});

},
returnp13nId: function ($span) {
  var spanId = $span.attr('class');
  return spanId.substr(15, spanId.length);
},
getTotalCount: function getTotalCount(contentItem, segmentArrLength) {
  if(contentItem.soln_p13n_filterMax){
   return contentItem.soln_p13n_filterMax;
 } 
 else if(contentItem.soln_p13n_filterMin){
   return contentItem.soln_p13n_filterMin;
 }
 else if(contentItem.soln_p13n_JCRQuery){
   return segmentArrLength;
 }
 else{
   return 1;
 }
}
};
